body {
}

section.wdth_1366 {
  overflow: hidden;
  max-width: 1366px;
  margin: auto;
  height: 770px;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  .container {
    max-width: 1196px;
  }
}

.bg_color_img {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 24px;

  .mt_50 {
    margin-top: 50px;
  }

  .logo {
    margin-bottom: 60px;
  }

  .main_form {
    h1 {
      margin-bottom: 10px; /* Sign in */
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #363636;
    }

    p {
      margin-bottom: 30px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #899ca8;
    }
  }

  .input_main {
    margin-bottom: 18px;

    .form-label {
      margin-bottom: 7px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #899ca8;
      line-height: 19px;
    }

    span#basic-addon2{
      background: white;
      border: 1px solid #f5f1fe;
      border-left: none!important;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      cursor: pointer;
    }

    input {
      height: 52px;
      background: #ffffff;
      border: 1px solid #f5f1fe;
      border-radius: 20px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #363636;
      border-right: none;

      &:focus {
        box-shadow: none;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .forgot_pass {
    margin-bottom: 28px;
    a {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #363636;
      text-decoration: none;
      display: inline-block;
      &:hover {
        color: #3b7afe;
      }
    }
  }

  .main_btn {
    button.main_form_btn {
      width: 100%;
      height: 52px;
      background: #3b7afe;
      box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
      border-radius: 100px;
      // btn text
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: center;

      color: #ffffff;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.form_star {
  color: red;
}

.responsive_margin{}

@media (max-width: 1400px) {
  section.wdth_1366 {
    max-width: 95%;
    margin: auto;
    height: 670px;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    .container {
      max-width: 1096px;
    }
  }
}

@media (max-width: 1200px) {
  .bg_color_img {
    .input_main {
      margin-bottom: 18px;


    }

    a {
      text-decoration: none;
    }

    .main_btn {
      button.main_form_btn {
        width: 100%;
        height: 52px;
      }
    }
  }

  
section.wdth_1366 {
    max-width: 1024px;
    margin: auto;
    height: 600px;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  
    .container {
      max-width: 896px;
    }
  }
}

@media (max-width: 1024px) {

  .responsive_margin{
    margin: auto;
  }


    
  section.wdth_1366 {
    background-image: unset!important;
      max-width: 750px;
      margin: auto;
      height: 600px;
      background-repeat: no-repeat;
      background-position: top right;
      background-size: contain;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    
      .container {
        max-width: 960px;
      }
    }
  }


.mb_20{
    margin-bottom: 30px!important;
  }