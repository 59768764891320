.container,
.container-fluid {
  max-width: 1366px;
}

.Links {
  text-decoration: none !important;
}

.onboarding-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 24px;
  margin: 20px 40px;
  overflow: hidden !important;
}

.onboarding-container .signin-container {
  padding-left: 95px;
}

.onboarding-container .signin-container .ezfi-logo {
  padding: 50px 0 60px 0;
}

.onboarding-container .signin-container .onboarding-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #363636;
}

.onboarding-container .signin-container .onboarding-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #899ca8;
  padding-top: 10px;
}

.onboarding-container .signin-container .signinform {
  padding: 30px 0 0 0;
}

.onboarding-container .signin-container .onboarding-placeholders {
  width: 70%;
  height: 52px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}

.onboarding-container .signin-container .onboarding-placeholders:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border: 1px solid #3b7afe;
}

.onboarding-container .signin-container .onboarding-placeholders:hover {
  border: 1px solid #3b7afe;
  -webkit-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}

.onboarding-container .signin-container .form-labels {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #899ca8;
}

.onboarding-container .signin-container .form-labels span {
  color: red;
}

.onboarding-container .signin-container .forgot-password {
  /* Forgot Password? */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #363636;
  padding-bottom: 25px;
}

.onboarding-container .signin-container .onboarding-btn {
  background: #3b7afe;
  -webkit-box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
          box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  width: 70%;
  height: 52px;
  font-size: 16px;
}

.onboarding-container .signin-container .onboarding-btn:hover {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: white;
  color: #3b7afe;
}

.onboarding-container .woman-img {
  max-width: 85%;
}

.otp {
  overflow: hidden !important;
}

.otp input {
  width: 55px !important;
  height: 50px;
  background: #ffffff;
  border: 1px solid #cfcdd6;
  border-radius: 20px;
  margin: 0 7px !important;
}

.otp input:focus {
  outline: none;
  border: 3px solid #5fbf24 !important;
}

.resend-code {
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
  color: #899ca8;
  margin-bottom: 20px;
}

nav a {
  color: red !important;
}

.validation-error {
  color: red;
}

body {
  background: #fbfbfd;
}

.dashboard-body {
  margin: 30px 0;
}

.dashboard-body .dashboard-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #363636;
}

.dashboard-body .statistics-section {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 30px 0 40px 0;
}

.dashboard-body .statistics-section .stats {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  color: #363636;
  padding: 20px 0 0 30px;
}

.dashboard-body .statistics-section .icon-box {
  padding-top: 32px;
  padding-left: 32px;
}

.dashboard-body .statistics-section .icon-box h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
  margin-bottom: 2px;
  text-align: left;
}

.dashboard-body .statistics-section .icon-box p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #899ca8;
  text-align: left;
}

.dashboard-body .patient-section {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 30px 0;
}

.dashboard-body .patient-section .patients {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  color: #363636;
  padding: 20px 0 0 30px;
}

.dashboard-body .patient-section canvas {
  height: 60% !important;
  width: 90% !important;
  margin: auto;
}

.dashboard-body .care-section {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 30px 10px 40px 10px;
  min-height: 430px;
}

.dashboard-body .care-section canvas {
  width: 90% !important;
  height: 200px !important;
  margin: auto;
  margin-top: 50px;
}

.dashboard-body .care-section .care {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #363636;
  padding-left: 30px;
}

.dashboard-body .care-section [type="button"]:not(:disabled),
.dashboard-body .care-section [type="reset"]:not(:disabled),
.dashboard-body .care-section [type="submit"]:not(:disabled),
.dashboard-body .care-section button:not(:disabled) {
  cursor: pointer;
  background: #f6f8fa;
  border: none;
  color: #363636;
  font-size: 12px;
  font-weight: 600;
  background: #f6f8fa;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 10px;
  height: 38px;
}

.dashboard-body .plan-section {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 30px 10px 40px 10px;
  min-height: 430px;
}

.dashboard-body .plan-section canvas {
  max-width: 90% !important;
  max-height: 100% !important;
  margin: auto;
}

.dashboard-body .plan-section .care {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #363636;
  padding-left: 30px;
}

.dashboard-body .plan-section [type="button"]:not(:disabled),
.dashboard-body .plan-section [type="reset"]:not(:disabled),
.dashboard-body .plan-section [type="submit"]:not(:disabled),
.dashboard-body .plan-section button:not(:disabled) {
  cursor: pointer;
  background: #f6f8fa;
  border: none;
  color: #363636;
  font-size: 12px;
  font-weight: 600;
  background: #f6f8fa;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 10px;
  height: 38px;
}

.dashboard-sidebar {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 900px;
}

.dashboard-sidebar .ezfi-logo-dashboard {
  margin-top: 20px;
  margin-bottom: 30px;
  margin: 20px 0 30px 24px;
  width: 122px;
}

.dashboard-sidebar ul {
  text-decoration: none;
  list-style: none;
  padding-left: 8px;
}

.dashboard-sidebar ul li {
  text-decoration: none;
  color: #899ca8;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  position: relative;
}

.dashboard-sidebar ul li a {
  text-decoration: none;
  color: #899ca8;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-left: 10px;
  cursor: pointer;
  min-width: 182px;
  min-height: 44px;
  /* identical to box height */
  /* 3 */
}

.dashboard-sidebar ul li a .nav-bg {
  padding-left: 7px;
}

.dashboard-sidebar ul li a .settings-icon {
  padding-right: 10px;
}

.dashboard-sidebar ul li a.active {
  color: #3b7afe;
  font-weight: 700;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

.dashboard-sidebar ul li a.active .nav-bg {
  background-color: #3b79fe25;
  width: 182px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
  padding-left: 7px;
}

.dashboard-sidebar ul li a.active path {
  fill: #3b7afe;
}

.dashboard-sidebar ul li a.active ::after {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  content: " ";
  background-image: url("/src/assets/images/activesection.png");
  background-repeat: no-repeat;
  height: 38px;
  width: 28px;
  position: absolute;
  right: -24px;
  bottom: 2px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
  padding: 0;
}

.dashboard-sidebar ul li .non-active {
  text-decoration: none;
  color: #899ca8 !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 17px;
  padding-left: 10px;
  cursor: pointer;
  min-width: 182px;
  min-height: 44px;
  background: transparent !important;
}

.dashboard-sidebar ul li .non-active path {
  fill: #899ca8 !important;
}

.dashboard-sidebar ul li .non-active ::after {
  display: none !important;
}

.all-section-top-gap {
  margin-top: 30px;
}

.notification-modal .modal-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  padding: 10px !important;
}

.notification-modal .modal-content {
  border-radius: 15px;
  width: 100% !important;
}

.notification-modal .modal-dialog {
  margin-right: 30px;
  margin-top: 90px;
}

.notification-modal .modal-body .header h6 {
  font-weight: 500;
}

.notification-modal .modal-body .header h6 span {
  font-size: 18px;
  font-weight: 500;
}

.notification-modal .modal-body .header .btns-time {
  cursor: pointer;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.notification-modal .modal-body .notification-container {
  height: calc(100vh - 235px);
  overflow-y: scroll;
}

.notification-modal .modal-body .notification-container::-webkit-scrollbar {
  display: none;
}

.LineGraph button:not(:disabled) {
  cursor: pointer;
  background: #f6f8fa;
  border: none;
  text-align: right;
  height: 48px;
  padding: 0 10px;
  border-radius: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #363636;
}

@media (max-width: 900px) {
  .dashboard-body .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    width: 300px !important;
  }
}

.top-bar .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  width: 400px;
  height: 50px;
  margin-bottom: 20px;
  border-radius: 14px;
  border: 1px solid #f5f1fe !important;
  background: white;
}

.top-bar .MuiOutlinedInput-notchedOutline {
  border-color: white;
}

.top-bar .search-box .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #3b7afe;
}

.top-bar .notification-box {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 12px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.top-bar .notification-box svg {
  margin: 14px 12px 0 0;
}

.top-bar .notification-box:hover {
  background-color: #3b7afe;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.top-bar .notification-box:hover path {
  fill: white;
}

.top-bar .profile-box {
  background-color: white;
  border: 1px solid #f5f1fe;
  border-radius: 12px;
  height: 50px;
  width: 200px;
  margin-left: 10px;
  cursor: pointer;
}

.top-bar .profile-box .profile-img {
  margin: 6px 7px;
}

.top-bar .profile-box .profile-name {
  /* Evan Yates */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #363636;
  margin: 10px 0;
}

.top-bar .profile-box:hover {
  background-color: #3b7afe;
  color: white;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.top-bar .profile-box:hover path {
  fill: white;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.top-bar .profile-box:hover .profile-name {
  color: white;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.top-bar .profile-box.active {
  background-color: #3b7afe;
  color: white;
}

.top-bar .profile-box.active path {
  fill: white;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.top-bar .profile-box.active .profile-name {
  color: white;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.top-bar .right-section .dropdown-toggle::after {
  display: none !important;
}

.top-bar .right-section .dropdown-toggle.btn.btn-primary {
  background: none !important;
  border: none;
  padding: 0;
  margin: 0;
  width: 200px;
  height: 50px;
}

.top-bar .right-section button#dropdown-menu-align-end:focus {
  /* outline: none!important; */
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.top-bar .right-section .dropdown-link {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636 !important;
  text-decoration: none;
}

.user-filter-btn {
  color: black;
}

.user-filter-btn button#dropdown-basic-button {
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  min-width: 166px;
  min-height: 52px;
  border: none;
  text-align: left;
  position: relative;
}

.user-filter-btn .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  background-image: url("/src/assets/images/arrow.png") !important;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  color: #989898;
  position: absolute;
  right: 20px;
  top: 45%;
}

.validations {
  color: red !important;
  margin-top: 3px;
  margin-left: 5px;
  display: inherit;
}

.validation-margin form > div + div {
  margin-top: 18px;
}

section.wdth_1366 {
  overflow: hidden;
  max-width: 1366px;
  margin: auto;
  height: 770px;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
}

section.wdth_1366 .container {
  max-width: 1196px;
}

.bg_color_img {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 24px;
}

.bg_color_img .mt_50 {
  margin-top: 50px;
}

.bg_color_img .logo {
  margin-bottom: 60px;
}

.bg_color_img .main_form h1 {
  margin-bottom: 10px;
  /* Sign in */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #363636;
}

.bg_color_img .main_form p {
  margin-bottom: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #899ca8;
}

.bg_color_img .input_main {
  margin-bottom: 18px;
}

.bg_color_img .input_main .form-label {
  margin-bottom: 7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #899ca8;
  line-height: 19px;
}

.bg_color_img .input_main span#basic-addon2 {
  background: white;
  border: 1px solid #f5f1fe;
  border-left: none !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
}

.bg_color_img .input_main input {
  height: 52px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  border-right: none;
}

.bg_color_img .input_main input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.bg_color_img a {
  text-decoration: none;
}

.bg_color_img .forgot_pass {
  margin-bottom: 28px;
}

.bg_color_img .forgot_pass a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #363636;
  text-decoration: none;
  display: inline-block;
}

.bg_color_img .forgot_pass a:hover {
  color: #3b7afe;
}

.bg_color_img .main_btn button.main_form_btn {
  width: 100%;
  height: 52px;
  background: #3b7afe;
  -webkit-box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
          box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}

.bg_color_img .main_btn button.main_form_btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.form_star {
  color: red;
}

@media (max-width: 1400px) {
  section.wdth_1366 {
    max-width: 95%;
    margin: auto;
    height: 670px;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
  }
  section.wdth_1366 .container {
    max-width: 1096px;
  }
}

@media (max-width: 1200px) {
  .bg_color_img .input_main {
    margin-bottom: 18px;
  }
  .bg_color_img a {
    text-decoration: none;
  }
  .bg_color_img .main_btn button.main_form_btn {
    width: 100%;
    height: 52px;
  }
  section.wdth_1366 {
    max-width: 1024px;
    margin: auto;
    height: 600px;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
  }
  section.wdth_1366 .container {
    max-width: 896px;
  }
}

@media (max-width: 1024px) {
  .responsive_margin {
    margin: auto;
  }
  section.wdth_1366 {
    background-image: unset !important;
    max-width: 750px;
    margin: auto;
    height: 600px;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
  }
  section.wdth_1366 .container {
    max-width: 960px;
  }
}

.mb_20 {
  margin-bottom: 30px !important;
}

.Responsive_Navbar .navbar {
  background-color: #FFFFFF !important;
  padding: 15px 0;
}

.Responsive_Navbar .navbar .res-img {
  width: 48px;
  height: 48px;
}

.Responsive_Navbar .navbar .notification-box {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 26px;
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.Responsive_Navbar .navbar .notification-box svg {
  margin: 12px 12px 0 0;
}

.Responsive_Navbar .navbar .notification-box:hover {
  background-color: #3b7afe;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.Responsive_Navbar .navbar .notification-box:hover path {
  fill: white;
}

.Responsive_Navbar .navbar .navbar-toggler {
  text-decoration: none;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #F5F1FE;
}

.Responsive_Navbar .navbar .navbar-toggler:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border: none;
}

.navbar-nav a {
  text-decoration: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 15px;
  color: #899ca8;
  cursor: pointer;
  padding-left: 10px;
  margin-top: 20px;
}

.navbar-nav a path {
  fill: #899ca8;
}

.navbar-nav a:hover {
  color: #3b7afe;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.navbar-nav a:hover path {
  fill: #3b7afe;
}

.navbar-nav a.active {
  background-color: #3B7AFE;
  padding: 10px 10px;
  border-radius: 12px;
  color: #F5F1FE;
}

.navbar-nav a.active path {
  fill: #F5F1FE;
}

@media (max-width: 1200px) {
  .responsive-hide {
    display: none;
  }
}

.logout-modal {
  border-radius: 10px;
}

.logout-modal .modal-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #363636;
  border: none;
}

.logout-modal .modal-header .modal-content .modal_main_content {
  padding: 27px !important;
}

.logout-modal .modal-body {
  /* Are you sure you want to log out? */
  font-family: "Nunito Sans", "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 141.5%;
  /* or 28px */
  /* 3 */
  color: #899ca8;
  padding: 0 16px;
}

.logout-modal .modal-footer {
  border: none;
  padding: 50px 12px;
}

.modal_blue_btn {
  background: #3b7afe;
  -webkit-box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
          box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  width: 167px;
  height: 52px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}

.modal_blue_btn:hover {
  background: #ffffff;
  color: #3b7afe;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.modal_gray_btn {
  /* Cancel */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #899CA8;
  background: none;
  border: none;
}

.modal_gray_btn:focus, .modal_gray_btn:hover {
  background: none;
  border: none;
}

.modal-content {
  width: 460px;
  border-radius: 24px;
}

.modal_main_content {
  padding: 45px;
}

.ezfi-modal .modal-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #363636;
  margin-bottom: 25px;
}

.ezfi-modal .modal-header {
  padding: 0;
  border-bottom: none;
}

.ezfi-modal .modal-body {
  padding: 0;
}

.ezfi-modal .modal-footer {
  padding: 0;
  border: none;
}

.ezfi-modal .btn-close {
  margin-bottom: 20px;
}

.fade.customModal.undefined.modal.show {
  padding-left: 0 !important;
}

.filter-txt {
  /* Evan Yates */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
  display: inline-block;
  padding-left: 9px;
  margin-right: 10px;
}

.dropdown-menu.show {
  background: #FFFFFF;
  border: 1px solid #F5F1FE;
  border-radius: 20px;
  font-family: 'Nunito Sans','Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #363636;
  height: 103px;
  width: 157px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px 10px;
}

.sure {
  /* Are you sure you want to log out? */
  font-family: 'Nunito Sans','Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 141.5%;
  /* or 28px */
  /* 3 */
  color: #899CA8;
}

.delete-modal-btns {
  margin-top: 58px;
}

.settings-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}

.settings-body {
  margin: 30px 0;
}

.settings-body .settings-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #363636;
  margin-bottom: 30px;
}

.settings-body .settings-bar {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  height: 750px;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}

.settings-body .settings-bar .settings-navigation {
  margin-top: 30px;
}

.settings-body .settings-bar .settings-navigation ul {
  text-decoration: none;
  list-style: none;
  padding-left: 20px;
}

.settings-body .settings-bar .settings-navigation ul li {
  margin-top: 22px;
}

.settings-body .settings-bar .settings-navigation ul li a {
  text-decoration: none;
  color: #899ca8;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-left: 7px;
  /* identical to box height */
  /* 3 */
}

.settings-body .settings-bar .settings-navigation ul li a .content-nav-bg {
  padding-left: 7px;
}

.settings-body .settings-bar .settings-navigation ul li a .settings-icon {
  padding-right: 10px;
}

.settings-body .settings-bar .settings-navigation ul li a.active {
  color: #3b7afe;
  font-weight: 700;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  text-align: center;
}

.settings-body .settings-bar .settings-navigation ul li a.active .content-nav-bg {
  background-color: #3b79fe25;
  width: 220px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
  padding-left: 7px;
}

.settings-body .settings-bar .settings-navigation ul li a.active path {
  fill: #3b7afe;
}

.profile-component {
  margin: 30px 35px;
}

.profile-component .edit-icon svg {
  cursor: pointer;
}

.profile-component .profile-title {
  margin-bottom: 32px;
}

.profile-component .profile-title .profile-name {
  /* Alex Joe */
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #121212;
  margin-left: 10px;
}

.profile-component .profile-details .role {
  /* Role: */
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #899ca8;
  width: 100px;
}

.profile-component .profile-details .admin {
  /* Admin */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  /* 4 */
  color: #363636;
}

.profile-component .profile-details .mt_17 {
  margin-top: 17px;
}

.changepassword-component {
  margin: 30px;
}

.changepassword-component h2 {
  /* Change Password */
  font-family: "Nunito Sans", "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #121212;
  margin-bottom: 30px;
}

.changepassword-component .input_setting {
  margin-bottom: 18px;
}

.changepassword-component .input_setting .form-label {
  margin-bottom: 7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #899ca8;
  line-height: 19px;
}

.changepassword-component .input_setting span#basic-addon2 {
  background: #ffffff;
  border: 1px solid #d8e0f0;
  border-radius: 7px;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.changepassword-component .input_setting input {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #d8e0f0;
  border-radius: 7px;
  height: 50px;
  border-right: none;
}

.changepassword-component .input_setting input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.changepassword-component .save_btn {
  width: 167px;
  height: 52px;
  background: #3b7afe;
  -webkit-box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
          box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
}

.deleteaccount-component {
  margin: 30px;
}

.deleteaccount-component .deleteaccount-heading {
  /* Delete Account */
  font-family: "Nunito Sans", "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #121212;
}

.deleteaccount-component p {
  font-family: "Nunito Sans", "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #989898;
  margin: 20px 0 25px 0;
}

.deleteaccount-component .input_setting {
  margin-bottom: 18px;
}

.deleteaccount-component .input_setting .form-label {
  margin-bottom: 7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #899ca8;
  line-height: 19px;
}

.deleteaccount-component .input_setting span#basic-addon2 {
  background: #ffffff;
  border: 1px solid #d8e0f0;
  border-radius: 7px;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.deleteaccount-component .input_setting input {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #d8e0f0;
  border-radius: 7px;
  height: 50px;
  border-right: none;
}

.deleteaccount-component .input_setting input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.deleteaccount-component .save_btn {
  width: 167px;
  height: 52px;
  background: #3b7afe;
  -webkit-box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
          box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
}

.ez-statistics-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 22px 30px;
}

.ez-statistics-container img {
  min-width: 48px;
}

.ez-statistics-container .heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
  margin-bottom: 32px;
}

.ez-statistics-container h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
  margin-bottom: 0;
}

.ez-statistics-container p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #899ca8;
}

.ez-statistics-container .ml_20 {
  margin-left: 20px;
}

.ez-graph-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 30px;
  margin-top: 25px;
}

.ez-graph-container h3 {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}

.ez-graph-container .graph-section {
  padding: 30px;
}

.ez-pay-dashboard button:not(:disabled) {
  cursor: pointer;
  background: #ffffff;
  border: none;
  text-align: right;
  height: 48px;
  padding: 0 10px;
  border-radius: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #363636;
}

.ez-pay-dashboard .gross-sales-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 217px;
}

.ez-pay-dashboard .gross-sales-bg h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #899ca8;
  margin: 16px 0 7px 19px;
}

.ez-pay-dashboard .gross-sales-bg h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #363636;
  margin-left: 20px;
}

.ez-pay-dashboard .gross-sales-bg canvas {
  padding: 0 10px;
}

.ez-pay-dashboard .revenue-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 377px;
  padding: 30px;
}

.ez-pay-dashboard .revenue-bg .form-select {
  background-color: #f6f8fa;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #363636;
  margin-bottom: 10px;
}

.ez-pay-dashboard .revenue-bg .form-select:focus {
  border: none;
}

.ez-pay-dashboard .revenue-bg h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #0a1629;
  margin-bottom: 30px;
}

.ez-pay-dashboard .earning-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 163px;
  margin-top: 32px;
  padding: 25px;
}

.ez-pay-dashboard .earning-bg h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15.2528px;
  line-height: 24px;
  color: #363636;
  margin-bottom: 0;
}

.ez-pay-dashboard .earning-bg p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12.2022px;
  line-height: 18px;
  color: #899ca8;
  margin-top: 12px;
  margin-bottom: 0;
}

.ez-pay-dashboard .plans-graph-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  margin-top: 30px;
  min-height: 354px;
  padding: 32px;
}

.ez-pay-dashboard .plans-graph-container .stacked-chart {
  margin-top: 30px;
}

.ez-pay-dashboard .plans-graph-container .form-select {
  background-color: #f6f8fa;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #363636;
  margin-bottom: 10px;
}

.ez-pay-dashboard .plans-graph-container .form-select:focus {
  border: none;
}

.ez-pay-dashboard .plans {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #363636;
  margin-bottom: 30px;
}

.ez-pay-dashboard .ezpay-table {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 355px;
  margin-top: 30px;
  padding-top: 30px;
}

.ez-pay-dashboard .ezpay-table .table-padding {
  border: none;
  padding: 0 30px 0 30px;
}

.ez-pay-dashboard .ezpay-table .table-padding .membership-plan {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #363636;
}

.ez-pay-dashboard .ezpay-table .table-padding .view-membership-btn {
  background: #ffffff;
  border: 0.5px solid #5fbf24;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
  border-radius: 14px;
  color: #5fbf24;
  padding: 13px;
}

.ez-pay-dashboard .ezpay-table .table-padding .view-membership-btn:focus {
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
  background: #5fbf24;
  color: white;
}

.ez-pay-dashboard .ezpay-table .table-padding .view-membership-btn:hover {
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
  background: #5fbf24;
  color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.proposal-profile .default-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #363636;
}

.proposal-profile .add-new-btn {
  padding: 15px 41px;
}

.proposal-profile .dental-plan {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 20px;
  min-height: 129px;
}

.proposal-profile .dental-plan .dental-plan-heading {
  /* Dental Plan */
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #363636;
  margin-bottom: 10px;
}

.proposal-profile .dental-plan .dental-desc {
  /* lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
  margin-bottom: 8px;
}

.proposal-profile .dental-plan .duration {
  /* Duration : 3 Months */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
}

.proposal-profile .dental-plan .months {
  color: #3b7afe;
  font-weight: 600;
  font-family: "Inter";
  line-height: 24px;
  padding-left: 5px;
  font-size: 14px;
}

.proposal-profile .contract {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 20px;
  min-height: 129px;
}

.proposal-profile .contract .contract-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #363636;
}

.proposal-profile .status-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 179px;
  margin-top: 30px;
  padding: 20px;
}

.proposal-profile .status-container .status {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #899ca8;
  margin-top: 20px;
  margin-bottom: 27px;
}

.proposal-profile .status-container .status-response {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}

.proposal-profile .proposal-payment-plan {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 291px;
  margin-top: 30px;
  padding: 20px;
}

.proposal-profile .proposal-payment-plan .plan-no {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  margin-top: 5px;
}

.proposal-profile .proposal-payment-plan .plan-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
  margin-top: 10px;
}

.proposal-profile .proposal-payment-plan .plan-conditions {
  /* Payment Source: Split */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
  padding-left: 12px;
}

.proposal-profile .proposal-payment-plan .payment-condition-answer {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3b7afe;
  padding-left: 8px;
}

.proposal-profile .proposal-payment-plan .mt_10 {
  margin-top: 10px;
}

.proposal-profile .cost-of-service {
  /* Cost of Services */
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #363636;
  margin-top: 30px;
  margin-bottom: 20px;
}

.proposal-profile .cost-of-service-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 627px;
}

.proposal-profile .financing-plan-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 559px;
  margin-top: 30px;
  padding: 20px;
}

.proposal-profile .financing-plan-container .plan-no {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  margin-top: 5px;
}

.proposal-profile .financing-plan-container .plan-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
  margin-top: 10px;
}

.proposal-profile .financing-plan-container .plan-conditions {
  /* Payment Source: Split */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
}

.proposal-profile .financing-plan-container .payment-condition-answer {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3b7afe;
  padding-left: 8px;
}

.proposal-profile .financing-plan-container .mt_10 {
  margin-top: 10px;
}

.proposal-profile .financing-plan-container .pt_12 {
  padding-left: 12px;
}

.fee-schedule-upload-btn {
  border: 1px solid #3b7afe;
  -webkit-filter: drop-shadow(0px 11px 20px rgba(59, 122, 254, 0.16));
          filter: drop-shadow(0px 11px 20px rgba(59, 122, 254, 0.16));
  border-radius: 100px;
  background: #f5f1fe;
  color: #3b7afe;
  padding: 13px 19px;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}

.fee-schedule-upload-btn:hover, .fee-schedule-upload-btn:focus {
  background: #3b7afe;
  border: 1px solid #f5f1fe;
  color: #ffffff;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.fee-schedule-upload-btn:hover path, .fee-schedule-upload-btn:focus path {
  fill: #f5f1fe;
}

.user-management h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #363636;
  margin-top: 5px;
  margin-bottom: 30px;
}

.add-new-btn {
  background: #3b7afe;
  -webkit-box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
          box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  /* Add New */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  min-width: 167px;
  min-height: 52px;
}

.add-new-btn.active {
  background: #3b7afe;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
}

.add-new-btn:focus {
  background: #3b7afe !important;
  outline: none;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border: none;
}

.add-new-btn:hover {
  background: white !important;
  color: #3b7afe;
  outline: none;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border: 1px solid #3b7afe;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.filter-btn {
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 15px 27px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
}

.datagrid-table {
  border: none !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator,
.css-ptiqhd-MuiSvgIcon-root {
  display: block !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  color: inherit;
  display: none !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIcon {
  display: none !important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: #a1a0a0;
}

.table-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 725px;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: none !important;
}

.breadcrumbs ol.breadcrumb {
  margin: 0;
}

.breadcrumbs nav a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #899ca8 !important;
  text-decoration: none;
  margin: 0 !important;
}

.eyehide-bg {
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  width: 52px;
  height: 52px;
  border: none !important;
}

.eyehide-bg:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  background: #3b7afe;
}

.eyehide-bg:focus path {
  fill: white !important;
}

.eyehide-bg:hover {
  background: #3b7afe;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border: #3b7afe;
}

.eyehide-bg:hover path {
  fill: white !important;
}

.payment-card-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  width: 362px;
  height: 321px;
  margin: auto;
  margin-top: 20px;
}

.payment-card-bg .card-container {
  padding: 20px;
}

.payment-card-bg .card-container h2 {
  /* Orthodontic Treatment */
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
  margin-bottom: 10px;
}

.payment-card-bg .card-container p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
  margin-bottom: 26px;
}

.payment-card-bg .card-container hr {
  opacity: 0.1;
  /* 3 */
  border: 1px solid #899ca8;
}

.payment-card-bg .card-container .three-dots-bg {
  background: #fafafb;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  height: 30px;
  width: 30px;
}

.payment-card-bg .card-container .payment-source {
  /* Payment Source: Third Party */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-left: 12px;
  color: #363636;
}

.payment-card-bg .card-container .third-party {
  color: #3b7afe;
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter";
  line-height: 24px;
  margin-left: 12px;
}

.payment-card-bg .card-container .mb_10 {
  margin-bottom: 10px;
}

.payment-card-bg .card-container .mt_20 {
  margin-top: 20px;
}

.patient-card-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  width: 362px;
  height: 310px;
  margin: auto;
  margin-top: 20px;
}

.patient-card-bg .three-dots-bg {
  background: #fafafb;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.patient-card-bg .patient-card-container {
  padding: 22px 30px;
}

.patient-card-bg .patient-title {
  /* Peter Paul */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0a1629;
  padding-left: 10px;
}

.patient-card-bg .patient-data {
  /* Amount Received: */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  /* 3 */
  color: #899ca8;
}

.patient-card-bg .patient-data-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: right;
  /* 4 */
  color: #363636;
}

.patient-card-bg .mt_10 {
  margin-top: 10px;
}

.patient-card-bg .mt_15 {
  margin-top: 15px;
}

.patient-card-bg hr {
  opacity: 0.1;
  /* 3 */
  border: 1px solid #899ca8ec;
}

.patient-card-bg .icon-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  /* identical to box height */
  /* 3 */
  color: #899ca8;
  margin-left: 6px;
}

.patient-card-bg .pt_20 {
  padding-top: 20px;
}

.corporate-clients-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}

.corporate-clients-bg .corporate-clients-container {
  padding: 28px 22px;
}

.corporate-clients-bg .corporate-clients-container h2 {
  /* KFC */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0a1629;
  padding-left: 10px;
}

.corporate-clients-bg .corporate-clients-container .three-dots-bg {
  background: #fafafb;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.corporate-clients-bg .corporate-clients-container .client-data {
  /* Amount Received: */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  /* 3 */
  color: #899ca8;
}

.corporate-clients-bg .corporate-clients-container .client-data-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: right;
  /* 4 */
  color: #363636;
}

.corporate-clients-bg .corporate-clients-container .mt_10 {
  margin-top: 10px;
}

.corporate-clients-bg .corporate-clients-container .mt_15 {
  margin-top: 15px;
}

.corporate-clients-bg .corporate-clients-container hr {
  opacity: 0.1;
  /* 3 */
  border: 1px solid #899ca8ec;
}

.corporate-clients-bg .corporate-clients-container .icon-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  /* identical to box height */
  /* 3 */
  color: #899ca8;
  margin-left: 6px;
}

.corporate-clients-bg .corporate-clients-container .pt_20 {
  padding-top: 20px;
}

.financing-card-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  width: 360px;
  height: 600px;
  margin: auto;
}

.financing-card-bg .financing-card-container {
  padding: 20px 20px;
}

.financing-card-bg .three-dots-bg {
  background: #fafafb;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  height: 30px;
  width: 30px;
}

.financing-card-bg h3 {
  /* Finance Plan*/
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
  margin: 0;
  margin-top: 10px;
}

.financing-card-bg p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
  margin: 0;
  margin-top: 10px;
}

.financing-card-bg hr {
  opacity: 0.1;
  border: 1px solid #899ca8;
}

.financing-card-bg .payment-source {
  /* Payment Source: Third Party */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-left: 12px;
  color: #363636;
}

.financing-card-bg .third-party {
  color: #3b7afe;
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter";
  line-height: 24px;
  margin-left: 12px;
}

.financing-card-bg .mb_10 {
  margin-bottom: 20px;
}

.content-management {
  padding: 37px 30px;
  text-align: left;
}

.content-management h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #121212;
  padding-top: 10px;
}

.content-management .terms {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #121212;
}

.content-management .mt_10 {
  margin-top: 10px;
}

.content-management .mt_30 {
  margin-top: 25px;
}

.faq-accordion {
  margin-top: 20px;
}

.faq-accordion .accordion {
  border: none;
}

.faq-accordion .accordion button {
  background: none;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #121212;
  border-bottom: 0;
}

.faq-accordion .accordion button:focus,
.faq-accordion .accordion button .active {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.faq-accordion .accordion .accordion-item {
  border: none;
}

.faq-accordion .accordion .accordion-body {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #989898;
  border: none;
}

.faq-accordion .accordion .accordion-button::after {
  background-image: url("/src/assets/images/arrow.png");
  background-size: 80% !important;
}

.membership-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}

.membership-bg .membership-container {
  padding: 12px 30px;
}

.membership-bg .membership-container .membership-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 18px;
  margin-bottom: 8px;
  /* body copy */
  color: #899ca8;
}

.membership-bg .membership-container label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}

.membership-bg .membership-container .form-check-inline {
  margin-right: 2rem;
  margin-top: 10px;
}

.membership-bg .membership-container select.form-select {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}

.membership-bg .membership-container select.form-select:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.membership-bg .membership-container .input-plan input {
  height: 52px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 15px;
}

.membership-bg .membership-container .input-plan input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.membership-bg .membership-container textarea {
  height: 83px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 15px;
}

.membership-bg .membership-container textarea:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.membership-bg .membership-container .appointment-btn {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 52px;
  border: 1px solid #3b7afe;
  -webkit-filter: drop-shadow(0px 11px 20px rgba(59, 122, 254, 0.16));
          filter: drop-shadow(0px 11px 20px rgba(59, 122, 254, 0.16));
  border-radius: 100px;
  background: white;
  color: #3b7afe;
  margin-bottom: 20px;
  margin-top: 40px;
}

.membership-bg .membership-container .appointment-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 29px 28px;
  width: 100%;
}

.membership-bg .membership-container .appointment-container h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
}

.membership-bg .membership-container .appointment-container .week-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
  margin-top: 20px;
  margin-bottom: 7px;
}

.membership-bg .membership-container .appointment-container .week-description {
  /* lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #899ca8;
  margin-bottom: 20px;
}

.membership-bg .membership-container .create-plan-btn {
  background: #3b7afe;
  -webkit-box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
          box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  padding: 15px 50px;
  margin-bottom: 44px;
}

.fees-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  height: 740px;
}

.fees-container .membership-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #899ca8;
}

.fees-container .fee-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #363636;
}

.fees-container .fee-padding {
  margin: 30px 30px 0 30px;
}

.fees-container .fee-padding input {
  height: 52px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}

.fees-container .fee-padding input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.fees-container .fee-padding .mt_plan {
  margin-top: 20px;
}

.fees-container .savings {
  border: 1px solid #97979727;
  border-radius: 10px;
  padding: 13px 18px;
  margin: 14px;
}

.fees-container .savings-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #363636;
}

.fees-container .savings-answer {
  color: #3b7afe;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-left: 30px;
}

.featured-plans {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #363636;
  margin-top: 10px;
}

.featured-plan-cards {
  background: #ffffff;
  border-radius: 20px;
  padding: 20px;
  margin-top: 30px;
}

.featured-plan-cards h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
  margin-top: 10px;
}

.featured-plan-cards p {
  /* Retail */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
}

.featured-plan-cards .patient-saving {
  background: #3b79fe34;
  border-radius: 10px;
  padding: 7px 18px;
}

.featured-plan-cards .patient-saving .patient-saving-text {
  /* Patient Saving: $100 */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #363636;
}

.featured-plan-cards .patient-saving .hundred {
  color: #3b7afe;
  font-size: 24px;
  font-weight: 600;
  font-family: "Inter";
}

.featured-plan-cards .patient-saving:hover {
  background: #3b7afe;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.featured-plan-cards .patient-saving:hover .patient-saving-text {
  color: white;
}

.featured-plan-cards .patient-saving:hover .hundred {
  color: white;
}

.featured-plan-cards hr {
  border: 1px solid #dadada;
  margin: 0;
  margin-bottom: 20px;
}

.featured-plan-cards .card-data-text {
  /* Total Appointments : */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #363636;
}

.featured-plan-cards .card-data-ans {
  /* 12 */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3b7afe;
  padding-left: 5px;
}

.featured-plan-cards .mt_15 {
  margin-top: 15px;
}

.table-padding {
  padding: 30px;
  margin-top: 30px;
}

.table-padding .membership-plan {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #363636;
}

.table-padding .view-membership-btn {
  background: #ffffff;
  border: 0.5px solid #5fbf24;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
  border-radius: 14px;
  color: #5fbf24;
  padding: 13px;
}

.table-padding .view-membership-btn:focus {
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
  background: #5fbf24;
  color: white;
}

.table-padding .view-membership-btn:hover {
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.3036);
  background: #5fbf24;
  color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.renewal-tracking {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 22px 30px 42px 30px;
}

.renewal-tracking h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
  margin-bottom: 30px;
}

.renewal-tracking .stats-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #363636;
}

.renewal-tracking .stats-desc {
  width: 93px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #899ca8;
}

.renewal-tracking img {
  margin-right: 20px;
}

.delete-bg {
  width: 48px;
  height: 48px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.delete-bg:hover {
  background: #ff5a4e;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}

.delete-bg:hover path {
  fill: #f5f1fe;
}

.css-1e2bxag-MuiDataGrid-root
.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
> .MuiDataGrid-cell {
  overflow: visible !important;
}

.user-button button#dropdown-basic-button {
  width: 30px;
  height: 30px;
  background: #fafafb;
  -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 8px;
  height: 30px;
  width: 30px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.user-button button#dropdown-basic-button:focus {
  border: none;
}

.user-button .dropdown-toggle::after {
  display: none;
}

.table-status {
  /* Status */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #e4f9e0;
  border-radius: 8px;
  width: 63px;
  height: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #5fbf24;
}

.user-modal textarea {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}

.user-modal textarea:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #3b7afe;
}

.user-modal input {
  width: 100%;
  height: 52px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}

.user-modal input:focus {
  border: 1px solid #3b7afe !important;
}

.user-modal select.form-select {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  width: 100%;
  height: 52px;
}

.user-modal select.form-select:focus {
  border: 1px solid #3b7afe !important;
}

.user-modal label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #899ca8;
  margin-bottom: 7px !important;
}

.user-modal input {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
}

.user-modal input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border: none;
}

.user-modal .form-select {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  margin-bottom: 18px;
}

.user-modal .form-select:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.user-modal p {
  /* Are you sure you want to deactivate these record? */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 141.5%;
  /* or 28px */
  /* 3 */
  color: #899ca8;
}

.user-modal .modal_cancel_btn {
  /* Cancel */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #899ca8;
  background: none;
  border: none;
  margin-left: 22px;
}

.user-modal .modal_cancel_btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.user-modal .modal_submit_btn {
  background: #3b7afe;
  -webkit-box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
          box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  width: 167px;
  height: 52px;
  /* Add */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-top: 19px;
}

.user-modal .modal_submit_btn:hover {
  border: 1px solid #3b7afe;
  background: white;
  color: #3b7afe;
}

.content-management-modal textarea {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}

.content-management-modal textarea:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #3b7afe;
}

.content-management-modal input {
  width: 100%;
  height: 52px;
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
}

.content-management-modal input:focus {
  border: 1px solid #3b7afe !important;
}

.content-management-modal select.form-select {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  width: 100%;
  height: 52px;
}

.content-management-modal select.form-select:focus {
  border: 1px solid #3b7afe !important;
}

.content-management-modal label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #899ca8;
  margin-bottom: 7px !important;
}

.content-management-modal input {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  margin-bottom: 18px;
}

.content-management-modal input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border: none;
}

.content-management-modal .form-select {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  margin-bottom: 18px;
}

.content-management-modal .form-select:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.content-management-modal p {
  /* Are you sure you want to deactivate these record? */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 141.5%;
  /* or 28px */
  /* 3 */
  color: #899ca8;
}

.content-management-modal .modal_cancel_btn {
  /* Cancel */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #899ca8;
  background: none;
  border: none;
  margin-left: 22px;
}

.content-management-modal .modal_cancel_btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.content-management-modal .modal_submit_btn {
  background: #3b7afe;
  -webkit-box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
          box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  width: 167px;
  height: 52px;
  /* Add */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-top: 19px;
}

.content-management-modal .modal_submit_btn:hover {
  border: 1px solid #3b7afe;
  background: white;
  color: #3b7afe;
}

button#dropdown-nav-basic-button {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  height: 44px;
}

button#dropdown-nav-basic-button .nav-ez-plan .dropdown-menu.show {
  position: absolute;
  inset: 0px auto auto 0px;
  -webkit-transform: translate3d(195px, 34px, 0px) !important;
          transform: translate3d(195px, 34px, 0px) !important;
}

button#dropdown-nav-basic-button .nav-ez-plan .dashboard-sidebar ul li a.active ::after {
  right: -37px;
}

.has-children .nav-child-list {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 100%;
  opacity: 0;
  visibility: visible;
  height: 307px;
  width: 213px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  z-index: 9;
  display: none;
  padding: 28px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #899ca8;
}

.has-children .nav-child-list .p_28 {
  padding-top: 28px;
}

.has-children .nav-child-list:after {
  content: normal !important;
}

.has-children .nav-child-list .nav-child-item:after {
  content: normal;
}

.has-children .nav-child-list a:after {
  content: normal !important;
}

.has-children:hover ul.nav-child-list {
  position: absolute;
  top: 0;
  left: 100%;
  background: white;
  opacity: 1;
  visibility: visible;
  display: block;
  -webkit-transition: 0.6s ease-in;
  transition: 0.6s ease-in;
}

@media (max-width: 1200px) {
  .ez-pay-dashboard .earning-bg h2 {
    font-size: 24px;
    margin-top: 20px;
  }
  .ez-pay-dashboard .earning-bg p {
    font-size: 16px;
  }
  .donutchart-container {
    width: 50%;
    margin: auto;
  }
  .revenue-bg {
    margin-top: 32px;
  }
  .settings-body .content-nav-bg {
    width: 200px !important;
  }
  .settings-body .settings-bar .settings-navigation {
    margin-top: 30px;
  }
  .settings-body .settings-bar .settings-navigation ul {
    text-decoration: none;
    list-style: none;
    padding-left: 10px;
  }
}

@media (max-width: 768px) {
  .LineGraph button:not(:disabled) {
    font-size: 12px;
  }
  .care-section canvas {
    width: auto !important;
    height: auto !important;
    margin: auto;
    margin-top: 50px;
  }
  .donutchart-container {
    width: 60%;
    margin: auto;
    margin-top: 20px;
  }
  .ez-pay-dashboard .earning-bg h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15.2528px;
    line-height: 24px;
    color: #363636;
    margin-bottom: 0;
  }
  .ez-pay-dashboard .earning-bg p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12.2022px;
    line-height: 18px;
    color: #899ca8;
    margin-top: 12px;
    margin-bottom: 0;
  }
}

@media (max-width: 992px) {
  .settings-body .settings-bar {
    height: auto;
    margin-bottom: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
  .settings-body .settings-bar .content-nav-bg {
    width: 200px !important;
    text-align: left !important;
    padding-right: 7px;
  }
  .settings-body .settings-bar ul {
    text-decoration: none;
    list-style: none;
    padding-left: 10px;
  }
  .settings-body .settings-bar ul li {
    display: inline-block !important;
    margin-left: 10px;
  }
}

@media (max-width: 375px) {
  .content-management {
    padding: 20px !important;
  }
  .content-management .user-button {
    padding-top: 10px !important;
  }
  .content-management h2 {
    font-size: 19px;
  }
  .user-management h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .user-management .add-new-btn {
    font-size: 16px;
    min-width: 120px;
    min-height: 42px;
    margin-bottom: 20px;
  }
  .user-filter-btn {
    color: black;
  }
  .user-filter-btn button#dropdown-basic-button {
    min-width: 120px;
    min-height: 42px;
  }
  .eyehide-bg {
    background: #ffffff;
    -webkit-box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
            box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    width: 42px;
    height: 42px;
    border: none !important;
    padding: 0;
  }
  .payment-card-bg {
    background: #ffffff;
    border: 1px solid #f5f1fe;
    border-radius: 20px;
    margin: auto;
    margin-top: 20px;
    width: 100%;
    min-height: 360px;
  }
  .financing-card-bg {
    width: 100%;
    height: auto;
  }
  .financing-card-bg h3 {
    font-size: 16px;
  }
  .financing-card-bg .mb_10 {
    margin-bottom: 10px;
  }
  .financing-card-bg .payment-source {
    font-size: 12px;
    margin-left: 6px;
  }
  .financing-card-bg .third-party {
    font-size: 12px;
  }
  .ez-pay-dashboard .revenue-bg {
    padding: 30px 10px 0 10px;
  }
  .ez-pay-dashboard .plans-graph-container {
    padding: 30px 10px 0 10px;
  }
  .ez-pay-dashboard .ezpay-table .table-padding {
    padding: 0 10px;
  }
  .date-range-picker button:not(:disabled) {
    font-size: 12px;
  }
}

@media (max-width: 700px) {
  .settings-body .settings-bar {
    height: auto;
    margin-bottom: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
  .settings-body .settings-bar .content-nav-bg {
    width: 230px !important;
    text-align: left !important;
    padding-right: 7px;
  }
  .settings-body .settings-bar ul {
    text-decoration: none;
    list-style: none;
    padding-left: 10px;
  }
  .settings-body .settings-bar ul li {
    display: block !important;
    margin-left: 10px;
  }
}

@media (max-width: 425px) {
  .dashboard-heading {
    font-size: 24px !important;
  }
}

@media (max-width: 575px) {
  .user-management h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .user-management .add-new-btn {
    margin-bottom: 20px;
  }
  .modal-content {
    min-width: 100%;
  }
}
