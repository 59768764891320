.user-management {
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #363636;
    margin-top: 5px;
    margin-bottom: 30px;
  }
}

.add-new-btn {
  background: #3b7afe;
  box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  /* Add New */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  // padding: 15px 27px;
  min-width: 167px;
  min-height: 52px;
  &.active{
    background: #3b7afe;
    outline: none;
    box-shadow: none;
    border: none;
  }
  &:focus{
    background: #3b7afe!important;
    outline: none;
    box-shadow: none!important;
    border: none;
  }
  &:hover{
    background: white!important;
    color: #3b7afe;
    outline: none;
    box-shadow: none!important;
    border: 1px solid #3b7afe;
    transition: 0.2s ease-in;
  }
}

.filter-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 15px 27px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
}

.datagrid-table {
  border: none !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator,
.css-ptiqhd-MuiSvgIcon-root {
  display: block !important;
}

// .MuiDataGrid-iconButtonContainer.css-ltf0zy-MuiDataGrid-iconButtonContainer{

// }


.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  color: inherit;
  display: none!important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIcon {
  display: none!important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: #a1a0a0;
}

.table-container {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  min-height: 725px;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: none !important;
}

.breadcrumbs {
  ol.breadcrumb {
    margin: 0;
  }
  nav a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #899ca8 !important;
    text-decoration: none;
    margin: 0 !important;
  }
}

// Role Management

.eyehide-bg {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  width: 52px;
  height: 52px;
  border: none !important;

  &:focus{
    box-shadow: none;
    outline: none;
    background: #3b7afe;

    path{
      fill: white!important;
    }
  }

  &:hover{
    background: #3b7afe;
    transition: 0.3s;
    border: #3b7afe;

    path{
      fill: white!important;
    }
  }
}

// Payment Plan Css Starts Here

.payment-card-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  width: 362px;
  height: 321px;
  margin: auto;
  margin-top: 20px;

  .card-container {
    padding: 20px;

    h2 {
      /* Orthodontic Treatment */
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #363636;
      margin-bottom: 10px;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #899ca8;
      margin-bottom: 26px;
    }

    hr {
      opacity: 0.1;
      /* 3 */
      border: 1px solid #899ca8;
    }

    .three-dots-bg {
      background: #fafafb;
      box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
      border-radius: 8px;
      height: 30px;
      width: 30px;
    }

    .payment-source {
      /* Payment Source: Third Party */
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-left: 12px;
      color: #363636;
    }

    .third-party {
      color: #3b7afe;
      font-size: 14px;
      font-weight: 600;
      font-family: "Inter";
      line-height: 24px;
      margin-left: 12px;
    }

    .mb_10 {
      margin-bottom: 10px;
    }

    .mt_20 {
      margin-top: 20px;
    }
  }
}

// EZ Plan
// Patients
// Start

.patient-card-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  width: 362px;
  height: 310px;
  margin: auto;
  margin-top: 20px;

  .three-dots-bg {
    background: #fafafb;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 8px;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  .patient-card-container {
    padding: 22px 30px;
  }

  .patient-title {
    /* Peter Paul */
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #0a1629;
    padding-left: 10px;
  }

  .patient-data {
    /* Amount Received: */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* 3 */
    color: #899ca8;
  }

  .patient-data-info {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-align: right;

    /* 4 */
    color: #363636;
  }

  .mt_10 {
    margin-top: 10px;
  }

  .mt_15 {
    margin-top: 15px;
  }

  hr {
    opacity: 0.1;
    /* 3 */
    border: 1px solid #899ca8ec;
  }

  .icon-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */

    /* 3 */
    color: #899ca8;
    margin-left: 6px;
  }

  .pt_20 {
    padding-top: 20px;
  }
}

// Corporate Clients

.corporate-clients-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;

  .corporate-clients-container {
    padding: 28px 22px;

    h2 {
      /* KFC */
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #0a1629;
      padding-left: 10px;
    }

    .three-dots-bg {
      background: #fafafb;
      box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
      border-radius: 8px;
      height: 30px;
      width: 30px;
      cursor: pointer;
    }

    .client-data {
      /* Amount Received: */
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      /* 3 */
      color: #899ca8;
    }

    .client-data-info {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
      text-align: right;

      /* 4 */
      color: #363636;
    }

    .mt_10 {
      margin-top: 10px;
    }

    .mt_15 {
      margin-top: 15px;
    }

    hr {
      opacity: 0.1;
      /* 3 */
      border: 1px solid #899ca8ec;
    }

    .icon-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 17px;
      /* identical to box height */

      /* 3 */
      color: #899ca8;
      margin-left: 6px;
    }

    .pt_20 {
      padding-top: 20px;
    }
  }
}

// Financing Plan

.financing-card-bg {
  background: #ffffff;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  width: 360px;
  height: 600px;
  margin: auto;

  .financing-card-container {
    padding: 20px 20px;
  }

  .three-dots-bg {
    background: #fafafb;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 8px;
    height: 30px;
    width: 30px;
  }

  h3 {
    /* Finance Plan*/
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #363636;
    margin: 0;
    margin-top: 10px;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #899ca8;
    margin: 0;
    margin-top: 10px;
  }

  hr {
    opacity: 0.1;
    border: 1px solid #899ca8;
  }
  .payment-source {
    /* Payment Source: Third Party */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-left: 12px;
    color: #363636;
  }

  .third-party {
    color: #3b7afe;
    font-size: 14px;
    font-weight: 600;
    font-family: "Inter";
    line-height: 24px;
    margin-left: 12px;
  }

  .mb_10{
    margin-bottom: 20px;
  }

}
