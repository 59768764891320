// Logout Modal

.logout-modal {
  border-radius: 10px;
  .modal-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #363636;
    border: none;

    .modal-content{

      .modal_main_content {
        padding: 27px!important;
    }
    }

  }

  .modal-body {
    /* Are you sure you want to log out? */

    font-family: "Nunito Sans", "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 141.5%;
    /* or 28px */

    /* 3 */
    color: #899ca8;
    padding: 0 16px;
  }

  .modal-footer {
    border: none;
    padding: 50px 12px;
  }
}

// End of Logout Modal

// Buttons of Modal

.modal_blue_btn {
  background: #3b7afe;
  box-shadow: 0px 11px 20px rgba(59, 122, 254, 0.16);
  border-radius: 100px;
  width: 167px;
  height: 52px;

  // btntext
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;

  &:hover {
    background: #ffffff;
    color: #3b7afe;
    transition: 0.2s ease-in;
  }
}

.modal_gray_btn {
/* Cancel */
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
text-align: center;
color: #899CA8;
background: none;
border: none;

&:focus,&:hover{
  background: none;
  border: none;
}

}

.modal-content {
  width: 460px;
  border-radius: 24px;
}

.modal_main_content {
  padding: 45px;
}

.ezfi-modal {
  .modal-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #363636;
    margin-bottom: 25px;
  }

  .modal-header {
    padding: 0;
    border-bottom: none;
  }
  .modal-body {
    padding: 0;
  }
  .modal-footer {
    padding: 0;
    border: none;
  }

  // .btn-close{
  //   background: #F9FAFA url("/src/assets/images/close.png") center/1em auto no-repeat;
  //   margin-bottom: 21px;
  //   &:focus{
  //     outline: none;
  //     box-shadow: none;
  //     border: none;
  //   }
  // }

  .btn-close {
    margin-bottom: 20px;
  }
}


.fade.customModal.undefined.modal.show {
  padding-left: 0 !important;
}

.filter-txt {
  /* Evan Yates */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #899ca8;
  display: inline-block;
  padding-left: 9px;
  margin-right: 10px;
}




.dropdown-menu.show{
background: #FFFFFF;
border: 1px solid #F5F1FE;
border-radius: 20px;
font-family: 'Nunito Sans','Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
color: #363636;
height: 103px;
width: 157px;
align-items: center;
padding: 20px 10px;
}






.sure{
  /* Are you sure you want to log out? */
font-family: 'Nunito Sans','Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 141.5%;
/* or 28px */

/* 3 */
color: #899CA8;


}

.delete-modal-btns{
  margin-top: 58px;
}